import React, { useState, useEffect } from "react";
import Input from "../common/form";
import request from "../common/api";
import toast from "react-hot-toast";

const Register = () => {
  if (localStorage.getItem("token")) {
    window.location.href = "/dashboard";
  }
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const recom = params.get('r');
  const [isPost, setIsPost] = useState(false);
  useEffect(() => {
    formData.recom = recom;
    if (localStorage.getItem("referrer")) formData.ref = localStorage.getItem("referrer");
  }, []);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    repassword: "",
    email: "",
    captach: "",
    recom: "",
    ref: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (isPost) return;
    e.preventDefault();
    // 用户名和密码不能为空
    if (!formData.username || !formData.password) {
      toast.error("请填写用户名和密码");
      return;
    }
    // 检查密码是否一致
    if (formData.password !== formData.repassword) {
      toast.error("两次密码输入不一致");
      // 刷新验证码
      const captchaImage = document.getElementById("captcha");
      captchaImage.src = "/captcha?v=" + Math.random();
      return;
    }
    setIsPost(true);
    toast.promise(
      // 发起注册请求
      request("post", "/api/user/register", formData),
      {
        loading: "注册中...",
        success: (response) => {
          // 根据返回的 status 字段判断注册是否成功
          if (response.status) {
            localStorage.removeItem("referrer");
            localStorage.setItem("register", "true");
            setTimeout(() => {
              window.location.href = "/login?u=" + formData.username;
            }, 1000);
            return "注册成功，正在跳转";
          } else {
            setIsPost(false);
            throw new Error("注册失败：" + response.message);
          }
        },
        error: (error) => {
          // 刷新验证码
          const captchaImage = document.getElementById("captcha");
          captchaImage.src = "/captcha?v=" + Math.random();
          setIsPost(false);
          return error.message || "注册失败，请稍后再试";
        }
      }
    );
  };

  return (
    <div className="flex-grow flex items-center justify-center mt-5">
      <div className="w-full max-w-md">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h1 className="text-2xl text-center mb-4">注册</h1>
          <Input
            title="用户名"
            label="username"
            type="text"
            name="username"
            placeholder="登录需要 可为邮箱地址"
            onChange={handleChange}
          />
          <Input
            title="密码"
            label="password"
            type="password"
            name="password"
            placeholder="登录需要"
            onChange={handleChange}
          />
          <Input
            title="确认密码"
            label="password"
            type="password"
            name="repassword"
            placeholder="登录需要"
            onChange={handleChange}
          />
          <Input
            title="电子邮箱"
            label="email"
            type="email"
            name="email"
            placeholder="可以不填 建议填写"
            onChange={handleChange}
          />
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              验证码
            </label>
            <div className="flex items-center">
              <input
                className="input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="captcha"
                placeholder="验证码"
                onChange={handleChange}
              />
              <img
                src="/captcha"
                alt="captcha"
                id="captcha"
                className="ml-2"
                onClick={(event) => {
                  const captchaImage = event.target;
                  if (captchaImage) {
                    captchaImage.src = "/captcha?v=" + Math.random();
                  } else {
                    console.error('captchaImage is undefined');
                  }
                }}
              />
            </div>
          </div>
          <Input
            title="推荐人"
            label="recom"
            type="number"
            name="recom"
            placeholder="没有可不填"
            onChange={handleChange}
            value={recom}
          />
          <div className="flex items-center justify-between mb-4">
            <button
              className="btn bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isPost}
            >
              {isPost ? <span className="loading loading-spinner"></span> : "注册"}
            </button>
            <a
              href="/login"
              className="text-sm text-gray-600 hover:text-gray-800"
            >
              已有账户？
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
